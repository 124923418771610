.loading-animation {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	overflow: hidden;
}

/* ::-webkit-scrollbar {
    width: 0px; 
    background: transparent;  
} */

@-webkit-keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-200px);
		transform: translateX(-200px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-left {
	0% {
		-webkit-transform: translateX(-200px);
		transform: translateX(-200px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}

@keyframes animateIn {
	0% {
		opacity: 0;
		transform: scale(0.6) translateY(-8px);
	}

	100% {
		opacity: 1;
	}
}

body {
	overscroll-behavior-y: none;
	overflow-y: hidden;
}

.loader {
	position: relative;
	height: 100vh;
	-webkit-animation: fade-in 250ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 250ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
	overflow: hidden;
}

.fade-in-anim {
	-webkit-animation: fade-in 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: fade-in 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.scale-in-center-anim {
	-webkit-animation: scale-in-center 200ms cubic-bezier(0.39, 0.575, 0.565, 1)
		both;
	animation: scale-in-center 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.slide-in-left-anim {
	-webkit-animation: slide-in-left-noteCard 200ms
		cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: slide-in-left-noteCard 200ms cubic-bezier(0.39, 0.575, 0.565, 1)
		both;
}

.slide-in-right-anim {
	-webkit-animation: slide-in-right-noteCard 200ms
		cubic-bezier(0.39, 0.575, 0.565, 1) both;
	animation: slide-in-right-noteCard 200ms cubic-bezier(0.39, 0.575, 0.565, 1)
		both;
}

.appMain-exit {
	-webkit-animation: fade-out 1s ease-out both;
	animation: fade-out 1s ease-out both;
}

.appBar {
	-webkit-animation: slide-in-top 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94)
		both;
}

@-webkit-keyframes slide-out-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
}
@keyframes slide-out-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
}
@-webkit-keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@keyframes scale-in-center {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		transform: scale(1);
		opacity: 1;
	}
}
@-webkit-keyframes slide-in-right-noteCard {
	0% {
		-webkit-transform: translateX(42px);
		transform: translateX(42px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
		opacity: 1;
	}
}
@keyframes slide-in-right-noteCard {
	0% {
		-webkit-transform: translateX(42px);
		transform: translateX(42px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@-webkit-keyframes slide-in-left-noteCard {
	0% {
		-webkit-transform: translateX(-42px);
		transform: translateX(-42px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-in-left-noteCard {
	0% {
		-webkit-transform: translateX(-42px);
		transform: translateX(-42px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@-webkit-keyframes swing-in-top-fwd {
	0% {
		-webkit-transform: rotateX(-100deg);
		transform: rotateX(-100deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
@keyframes swing-in-top-fwd {
	0% {
		-webkit-transform: rotateX(-100deg);
		transform: rotateX(-100deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
@-webkit-keyframes swing-in-top-bck {
	0% {
		-webkit-transform: rotateX(70deg);
		transform: rotateX(70deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
@keyframes swing-in-top-bck {
	0% {
		-webkit-transform: rotateX(70deg);
		transform: rotateX(70deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 0;
	}
	100% {
		-webkit-transform: rotateX(0deg);
		transform: rotateX(0deg);
		-webkit-transform-origin: top;
		transform-origin: top;
		opacity: 1;
	}
}
@-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes fade-out {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@-webkit-keyframes slide-in-blurred-right {
	0% {
		-webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}
@keyframes slide-in-blurred-right {
	0% {
		-webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}
@-webkit-keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes slide-in-top {
	0% {
		-webkit-transform: translateY(-1000px);
		transform: translateY(-1000px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

.w-100 {
	width: '100%';
}

.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}

.whitespace-pre {
	white-space: pre;
}